export const regions = [
    {"label": "Alabama", "option": "Alabama"},
    {"label": "Alaska", "option": "Alaska"},
    {"label": "Arizona", "option": "Arizona"},
    {"label": "Arkansas", "option": "Arkansas"},
    {"label": "California", "option": "California"},
    {"label": "Colorado", "option": "Colorado"},
    {"label": "Connecticut", "option": "Connecticut"},
    {"label": "Delaware", "option": "Delaware"},
    {"label": "Florida", "option": "Florida"},
    {"label": "Georgia", "option": "Georgia"},
    {"label": "Hawaii", "option": "Hawaii"},
    {"label": "Idaho", "option": "Idaho"},
    {"label": "Illinois", "option": "Illinois"},
    {"label": "Indiana", "option": "Indiana"},
    {"label": "Iowa", "option": "Iowa"},
    {"label": "Kansas", "option": "Kansas"},
    {"label": "Kentucky", "option": "Kentucky"},
    {"label": "Louisiana", "option": "Louisiana"},
    {"label": "Maine", "option": "Maine"},
    {"label": "Maryland", "option": "Maryland"},
    {"label": "Massachusetts", "option": "Massachusetts"},
    {"label": "Michigan", "option": "Michigan"},
    {"label": "Minnesota", "option": "Minnesota"},
    {"label": "Mississippi", "option": "Mississippi"},
    {"label": "Missouri", "option": "Missouri"},
    {"label": "Montana", "option": "Montana"},
    {"label": "Nebraska", "option": "Nebraska"},
    {"label": "Nevada", "option": "Nevada"},
    {"label": "New Hampshire", "option": "New Hampshire"},
    {"label": "New Jersey", "option": "New Jersey"},
    {"label": "New Mexico", "option": "New Mexico"},
    {"label": "New York", "option": "New York"},
    {"label": "North Carolina", "option": "North Carolina"},
    {"label": "North Dakota", "option": "North Dakota"},
    {"label": "Ohio", "option": "Ohio"},
    {"label": "Oklahoma", "option": "Oklahoma"},
    {"label": "Oregon", "option": "Oregon"},
    {"label": "Pennsylvania", "option": "Pennsylvania"},
    {"label": "Rhode Island", "option": "Rhode Island"},
    {"label": "South Carolina", "option": "South Carolina"},
    {"label": "South Dakota", "option": "South Dakota"},
    {"label": "Tennessee", "option": "Tennessee"},
    {"label": "Texas", "option": "Texas"},
    {"label": "Utah", "option": "Utah"},
    {"label": "Vermont", "option": "Vermont"},
    {"label": "Virginia", "option": "Virginia"},
    {"label": "Washington", "option": "Washington"},
    {"label": "West Virginia", "option": "West Virginia"},
    {"label": "Wisconsin", "option": "Wisconsin"},
    {"label": "Wyoming", "option": "Wyoming"},
    {"label": "Alberta", "option": "Alberta"},
    {"label": "British Columbia", "option": "British Columbia"},
    {"label": "Manitoba", "option": "Manitoba"},
    {"label": "New Brunswick", "option": "New Brunswick"},
    {"label": "Newfoundland and Labrador", "option": "Newfoundland and Labrador"},
    {"label": "Northwest Territories", "option": "Northwest Territories"},
    {"label": "Nova Scotia", "option": "Nova Scotia"},
    {"label": "Nunavut", "option": "Nunavut"},
    {"label": "Ontario", "option": "Ontario"},
    {"label": "Prince Edward Island", "option": "Prince Edward Island"},
    {"label": "Quebec", "option": "Quebec"},
    {"label": "Saskatchewan", "option": "Saskatchewan"},
    {"label": "Yukon", "option": "Yukon"},
    {"label": "Other", "option":"Other"}
];
